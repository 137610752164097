import React from "react";

import * as classes from "./NewsItem.module.css";

const NewsItem = ({ data }) => {
  return (
    <div className={classes.newsItemContainer}>
      <h1>{data.markdownRemark.frontmatter.title}</h1>
      <p>{data.markdownRemark.frontmatter.date}</p>
      <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}></div>
    </div>
  );
};

export default NewsItem;
