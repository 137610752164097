import React from "react";
import { graphql } from "gatsby";
import NewsItem from "../components/News/NewsItem";
import Seo from "../components/Seo/seo";

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        date
      }
      html
    }
  }
`;

const News = (props) => {
  return (
    <>
      <Seo title={props.data.markdownRemark.frontmatter.title} />
      <NewsItem data={props.data} />
    </>
  );
};

export default News;
